import { Box } from '@mui/material'

import { useETHPrice, useHONOPrice } from 'app/hooks/graph/useGraph'
import { formatCurrency } from 'app/utils/helper'

export const ChartTitle = () => {
  const { data: honoPrice } = useHONOPrice()
  const { data: ethPrice } = useETHPrice()

  return (
    <Box display='flex' alignItems='center' columnGap='4px' mt='-16px' color='#fff' fontSize='20px' fontWeight={500}>
      <img src='/hono-icon.svg' alt='HONO' width={36} height={36} />
      <p>{`HONO V2 PRICE: ${(honoPrice && ethPrice ? +honoPrice / +ethPrice : 0).toFixed(10)} ETH`}</p>
      {honoPrice && <p>{`(~${formatCurrency(honoPrice, 6)})`}</p>}
    </Box>
  )
}
